import styles from "../css/HomePageBlurbBox.module.css";

const HomePageBlurbBox = (props) => {
  return (
    <div
      style={{
        margin: "0",
        width: "80%",
        color: "white",
        paddingBottom: "5%",
      }}
    >
      {props.sectionTitle && <h1>{props.sectionTitle}</h1>}
      <div className={styles.education}>
        <img src={props.logoSrc} alt="Logo" />
        <div id={styles.textContent}>
          <p id={styles.title}>{props.Title}</p>
          <p>{props.Subtitle}</p>
          {props.Date ? <p>{props.Date}</p> : <></>}
        </div>
      </div>
    </div>
  );
};

export default HomePageBlurbBox;
