// Third-party libraries imports
import React from "react";

// Local imports
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import ProjectCard from "../Components/ProjectCard";

import AlternateNavBar from "../Components/AlternateNavBar";

import "../css/Portfolio.css";

/**
 * Contains cards that show the title, technology used, dates, and a description to current and completed projects.
 * @returns {JSX.Element} - The JSX element representing the portfolio page
 */
const Portfolio = () => {
  return (
    <div className="pageBasePortfolio">
      <NavBar />
      <AlternateNavBar />

      <div className="pageContentPortfolio">
        <h1 className="completedSectionHeader">Completed Projects</h1>
        <ProjectCard
          title="StyleU"
          tools="React, JavaScript, Miro, Figma"
          descripton="One of my first opprotunities leading a team of developers, StyleU was a semester length multiphase project whereby
          each team was responsible for planning, designing, and delivering an MVP mobile application."
          repoName="StyleU"
        />
        <h1 className="completedSectionHeader">Active Projects</h1>
        <ProjectCard
          title="Valve Database"
          tools="React, TypeScript, JavaScript, SQLite"
          activeDate="Ongoing"
          descripton="An experimental project to increase my skills in developing a more formal system like software, this is a management system a chemical plant administrator
          could use to maintain secure, server-side records of the all the plant's in-use and stock valves."
          repoName="plant-valve-manager"
        />
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
