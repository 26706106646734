// Third-party library imports
import React from "react";

import { FiMail } from "react-icons/fi";
import { ImGithub } from "react-icons/im";
import { BsLinkedin } from "react-icons/bs";

// Local imports
import AlternateNavBar from "../Components/AlternateNavBar";
import NameAndLinks from "../Components/NameAndLinks";

import styles from "../css/LandingPage.module.css";
import styles2 from "../css/NameAndLinks.module.css";
import "../css/LandingPageMobile.css";

import SelfImage from "../images/Selfie.jpg";

/**
 * Contains an introduction splash screen with small blurbs about my education, organizations, and work experiences.
 * @returns {JSX.Element} - The JSX element representing the Landing page
 */
const Home = () => {
  return (
    <div className={styles.pageBase}>
      <AlternateNavBar />
      <div className={styles.namePlateContainer}>
        <NameAndLinks
          Name="Kevyn Malveaux"
          Subtitle="Computer Scientist | Software Developer | IT Specialist"
        />
        <div id={styles.picAndLinks}>
          <img
            id={styles.profilePicture}
            src={SelfImage}
            alt="ProfilePicture"
          />
          <div id={styles2.links}>
            <a href="mailto:kevynmalveaux@gmail.com">
              <FiMail style={{ color: "white", fontSize: "2rem" }} />
            </a>
            <a href="https://www.linkedin.com/in/kevyn-malveaux-214270243/">
              <BsLinkedin style={{ color: "white", fontSize: "2rem" }} />
            </a>
            <a href="https://github.com/KMalveaux">
              <ImGithub style={{ color: "white", fontSize: "2rem" }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
