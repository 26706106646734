// Third-party library imports
import React from "react";

import { AiOutlineGithub } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";

// Local imports
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import HomePageBlurbBox from "../Components/HomePageBlurbBox";

import AlternateNavBar from "../Components/AlternateNavBar";

import "../css/About.css";

const About = () => {
  return (
    <div className="pageBaseAbout">
      <AlternateNavBar />

      {/* <NavBar /> */}
      <div className="pageContent">
        <h className="AboutHeader">About Me.</h>
        <div>
          <p className="AboutContent">
            Hello, my name is Kevyn Malveaux. I am a software developer with a
            specialty in full stack application and cloud development.
          </p>
          <p className="AboutContent">
            I have personal experience in coordinating and participating in the
            planning, development, and presentation of mobile and web
            applications and software.
          </p>
          <p className="AboutContent">
            I have worked on a wide range of projects from mobile development
            with a social media platform to software tools such as a small
            programming language compiler.
          </p>

          <p className="AboutContent">
            Feel free to reach out to me with any questions, collaboration
            opportunities, or to learn more about my work.
          </p>
          <div className="linksAbout">
            <a href="mailto:kevynmalveaux@gmail.com">
              <IoMdMail style={{ color: "white", fontSize: "2rem" }} />
              <p>kevynmalveaux@gmail.com</p>
            </a>
            <a href="https://www.linkedin.com/in/kevyn-malveaux-214270243/">
              <BsLinkedin style={{ color: "white", fontSize: "2rem" }} />
              <p>linkedin.com/in/kevyn-malveaux</p>
            </a>
            <a href="https://github.com/KMalveaux">
              <AiOutlineGithub style={{ color: "white", fontSize: "2rem" }} />
              <p>github.com/KMalveaux</p>
            </a>

            <HomePageBlurbBox
              sectionTitle="Education"
              logoSrc={require("../images/LSU-logo.png")}
              Title="Louisiana State University"
              Subtitle="B.S. in Computer Science | Minor in Digital Media Art and Engineering Technology"
              Date="Aug 2020 - May 2024"
            />
            <HomePageBlurbBox
              sectionTitle="Work Experience"
              logoSrc={require("../images/FullLogo_Transparent.webp")}
              Title="One Way Realty LLC"
              Subtitle="Tech Provider"
              Date="Apr 2024 - Present"
            />
            <HomePageBlurbBox
              logoSrc={require("../images/FiveGuysLogo.png")}
              Title="Five Guys LLC"
              Subtitle="Crew Member"
              Date="May 2022 - Aug 2022"
            />
          </div>

          <div>
            <p className="technologiesHeader">Skills</p>

            <div className="myStackHeadersAndIcons">
              <div className="listDiv">
                <div className="listDivItem">
                  <h1>Languages</h1>
                  <ul>
                    <li>Java</li>
                    <li>Python</li>
                    <li>JavaScript</li>
                    <li>TypeScript</li>
                    <li>SQL</li>
                    <li>C</li>
                    <li>Go</li>
                  </ul>
                </div>
                <div className="listDivItem">
                  <h1>Frameworks</h1>
                  <ul>
                    <li>React</li>
                    <li>React Native</li>
                    <li>Flutter</li>
                  </ul>
                </div>

                <div className="listDivItem">
                  <h1>Tools</h1>
                  <ul>
                    <li>Linux</li>
                    <li>Windows</li>
                    <li>Git</li>
                    <li>AWS</li>
                    <li>Azure</li>
                    <li>Vultr</li>
                  </ul>
                </div>
                <div className="listDivItem">
                  <h1>Methodologies</h1>
                  <ul>
                    <li>Agile</li>
                    <li>Scrum</li>
                    <li>CI/CD</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="hobbiesContainer">
            <h className="hobbiesHeader">Hobbies</h>
            <p>
              With my current area of focus residing in full stack web
              development, I aspire to gain the experience to work on back-end
              server and/or embedded software. So I enjoy in my free time
              working on personal projects that reflect this style of software
              that aligns with this goal.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
