// Third-party library imports
import React from "react";
import { AiOutlineGithub } from "react-icons/ai";

// Local imports
import styles from "../css/ProjectCard.module.css";

/**
 * ProjectCard component displays information about a project, including title, active date,
 * picture (if available), tools used, GitHub link, and description.
 *
 * @param {Object} props - The props object containing data for the project card.
 * @param {string} props.repoName - The name of the repository on GitHub.
 * @param {string} props.title - The title of the project.
 * @param {string} props.activeDate - The active date of the project.
 * @param {string} props.picture - The URL of the picture representing the project.
 * @param {string} props.pictureAlt - The alt text for the project picture.
 * @param {string} props.tools - The tools used in the project.
 * @param {string} props.description - The description of the project.
 * @returns {JSX.Element} - The JSX element representing the project card.
 */
const ProjectCard = (props) => {
  const githubUrl = `https://github.com/KMalveaux/${props.repoName}`;

  return (
    <div className={styles.projectCardContainer}>
      <div className={styles.projectCardContent}>
        <h id={styles.title}>{props.title}</h>
        <p id={styles.activeDate}>{props.activeDate}</p>
        {props.picture && (
          <div className={styles.imageContainer}>
            <img src={props.picture} alt={props.pictureAlt} />
          </div>
        )}

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p id={styles.tools}>{props.tools}</p>
          <p id={styles.linksContainerPortfolio}>
            <a href={githubUrl}>
              <AiOutlineGithub className={styles.linksPortfolio} />
            </a>
          </p>
        </div>
        <p id={styles.description}>{props.descripton}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
