import React from "react";
import styles from "../css/NameAndLinks.module.css";

const NameAndLinks = (props) => {
  return (
    <div className={styles.splashContainer}>
      <p id={styles.Starter}>I'm</p>
      <p id={styles.Name}>{props.Name}</p>
      <span id={styles.Subtitle}>
        {/* <p>a</p> */}
        <p>{props.Subtitle}</p>
      </span>
    </div>
  );
};

export default NameAndLinks;
