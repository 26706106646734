import React, { useEffect, useState } from "react";
import "../css/NavBar.css";
import { Link } from "react-router-dom";

const NavBar = (props) => {
  const [shouldFadeOut, setShouldFadeOut] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 200; // Adjust this value as per your requirement

      console.log("Current Scroll Position:", scrollPosition);

      if (scrollPosition >= scrollThreshold) {
        setShouldFadeOut(true);
      } else {
        setShouldFadeOut(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`NavContainer ${shouldFadeOut ? "fade-out" : ""}`}>
      <div className="NavContainer">
        <Link to="/" className="text">
          Home
        </Link>
        <Link to="/About" className="text">
          About
        </Link>
        <Link to="/Portfolio" className="text">
          Portfolio
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
