import React from "react";
import "../css/Footer.css";
import { Link } from "react-router-dom";

import { IoMdMail } from "react-icons/io";
import { BsLinkedin } from "react-icons/bs";
import { ImGithub } from "react-icons/im";

const Footer = () => {
  return (
    <div className="FooterContainer">
      <div className="PersonalInfoContainer">
        {/* <p className="PersonalInfoHeader">Kevyn Malveaux</p> */}
        <p className="PersonalInfoHeader"></p>
        <div className="LinksContainer">
          <a href="mailto:kmalve6@lsu.edu">
            <IoMdMail style={{ color: "white", fontSize: "2rem" }} />
            <p>kevynmalveaux@gmail.com</p>
          </a>
          <a href="https://www.linkedin.com/in/kevyn-malveaux">
            <BsLinkedin style={{ color: "white", fontSize: "2rem" }} />
            <p>linkedin.com/in/kevyn-malveaux</p>
          </a>
          <a href="https://github.com/KMalveaux">
            <ImGithub style={{ color: "white", fontSize: "2rem" }} />
            <p>github.com/KMalveaux</p>
          </a>
        </div>
      </div>
      <div className="FooterNavContainer">
        <div className="NavSectionHeaders">
          <Link to="/" className="HeaderLink">
            Home
          </Link>
          <Link to="/About" className="HeaderLink">
            About
          </Link>
          {/* <Link to="/Portfolio" className="HeaderLink">
            Portfolio
          </Link> */}
          <div className="NavSectionRoutes"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
