// Third Party Imports
import { FaHome } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { FaWrench } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";

import { useState } from "react";

import { Link } from "react-router-dom";

// Local Imports
import styles from "../css/AlternateNavBar.module.css";

/**
 *
 * @param IconComponent The component meant to be displayed as an icon on the NavBar. This should be a React Icons component
 * @param hoverText The text that appears on hover
 * @returns
 */
const NavBarItem = ({ IconComponent, hoverText, link }) => {
  const [showHoverText, setShowHoverText] = useState(false);

  const handleMouseEnter = () => {
    setShowHoverText((prevShowHoverText) => !prevShowHoverText);
  };

  return (
    <div className={styles.IconContainer}>
      <Link to={link} className={styles.Link}>
        <IconComponent
          className={styles.Icon}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseEnter}
        />
        {showHoverText ? <p id={styles.hoverText}>{hoverText}</p> : <></>}
      </Link>
    </div>
  );
};

const AlternateNavBar = () => {
  return (
    <div className={styles.navBarContainer}>
      <div className={styles.linksContainer}>
        <NavBarItem IconComponent={FaHome} hoverText="Home" link="/" />
        <NavBarItem
          IconComponent={IoPerson}
          hoverText="About me"
          link="/About"
        />
        {/* <NavBarItem
          IconComponent={FaWrench}
          hoverText="Portfolio"
          link="/Portfolio"
        /> */}
      </div>
      <FaArrowRight className={styles.indicatorArrow} />
    </div>
  );
};

export default AlternateNavBar;
